<template>
    <v-dialog v-model="modal" hide-overlay width="100%" max-width="1000">
        <v-card v-if="!loaded" color="primary" dark>
            <v-card-text>
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-if="loaded" justify-center align-center>
            <div class="mb-4">
                <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                <v-card-title class="justify-center">
                    {{ $t('invoices.issue_invoices_title') }} {{ requestInfo.orderId }}
                </v-card-title>
            </div>
            <v-card-subtitle
                v-if="issueInvoicesInfo.requestInvoiceAllowed !== false && issueInvoicesInfo.transactions.length"
                class="mt-2 mb-0 py-0"
            >
                {{ $t('invoices.issue_invoice_subtitle') }}
            </v-card-subtitle>
            <v-card-text
                v-if="issueInvoicesInfo.requestInvoiceAllowed !== false && issueInvoicesInfo.transactions.length"
            >
                <v-list-item
                    v-for="(transaction, index) in issueInvoicesInfo.transactions"
                    :key="transaction.serviceId"
                    class="pl-0"
                >
                    <div>
                        <v-checkbox v-model="serviceIds" :value="transaction.serviceId" hide-details>
                            <template v-slot:label>
                                <div>{{ transaction.description }}</div>
                                <v-list-item-subtitle>
                                    <strong :class="statusColor(transaction.status)">
                                        {{ $t(`bookingStatus.${status(transaction.status)}`) }}
                                    </strong>
                                    <strong class="ml-2">{{
                                        transaction.amount | priceFormat(transaction.currency)
                                    }}</strong>
                                </v-list-item-subtitle>
                            </template>
                        </v-checkbox>
                        <div
                            v-if="issueInvoicesInfo.partialInvoicingAllowed"
                            class="d-flex align-center ms-8 text--secondary flex-wrap"
                        >
                            <div class="mr-2">{{ $t('invoices.get_invoice_on') }}</div>
                            <div class="d-flex align-center">
                                <v-text-field
                                    v-model="invoiceIssueTransactions[index].percent"
                                    variant="outlined"
                                    density="compact"
                                    hide-details
                                    type="number"
                                    class="percent-input flex-grow-0"
                                    :disabled="!serviceIds.includes(transaction.serviceId)"
                                    @change="
                                        invoiceIssueTransactions[index].prepaymentAmount =
                                            Math.round(transaction.amount * $event) / 100
                                    "
                                />
                                <div class="mx-2">%=</div>
                                <v-text-field
                                    v-model="invoiceIssueTransactions[index].prepaymentAmount"
                                    variant="outlined"
                                    density="compact"
                                    hide-details
                                    type="number"
                                    class="amount-input flex-grow-0"
                                    :disabled="!serviceIds.includes(transaction.serviceId)"
                                    @change="
                                        invoiceIssueTransactions[index].percent =
                                            Math.round(($event / transaction.amount) * 10000) / 100
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </v-list-item>
            </v-card-text>
            <v-card-actions
                v-if="issueInvoicesInfo.requestInvoiceAllowed !== false && issueInvoicesInfo.transactions.length"
                class="pa-6 flex-wrap"
            >
                <div v-if="serviceIds.length">
                    {{ $t('invoices.amount_to_be_paid') }}: <strong>{{ totalPrepaymentAmount.toFixed(2) }}</strong>
                </div>
                <v-spacer />
                <div>
                    <v-btn
                        class="text-black mr-4"
                        color="white"
                        :disabled="issueInvoiceLoading"
                        @click="cancelIssueInvoices"
                    >
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        :disabled="!serviceIds.length"
                        :loading="issueInvoiceLoading"
                        @click="issueInvoices"
                    >
                        {{ $t('invoices.generate_invoice') }}
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, ISSUE_INVOICES_MODAL_EVENT, SHOW_INVOICES_MODAL_EVENT} from '@/utils/event-bus'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component({})
    export default class InvoicesIssueModal extends Vue {
        issueInvoicesInfo = {
            partialInvoicingAllowed: false,
            requestInvoiceAllowed: false,
            transactions: [],
            wholeOrderInvoice: false,
        }
        modal = false
        loaded = false
        requestInfo = {}
        invoiceType = null
        serviceIds = []
        invoiceIssueTransactions = []
        issueInvoiceLoading = false

        created() {
            EventBus.$on(ISSUE_INVOICES_MODAL_EVENT, this.showIssueInvoiceModal)
        }

        beforeUnmount() {
            EventBus.$off(ISSUE_INVOICES_MODAL_EVENT, this.showIssueInvoiceModal)
        }

        async showIssueInvoiceModal(rq) {
            this.loaded = false
            this.modal = true
            this.requestInfo = rq
            this.serviceIds = []
            this.invoiceType = rq.invoiceType
            await this.invoiceIssueInfo()

            this.loaded = true
        }

        async invoiceIssueInfo() {
            try {
                this.issueInvoicesInfo = await this.$api.invoiceIssueInfo.get({
                    orderId: this.requestInfo.objectId,
                    invoiceType: this.invoiceType,
                })
                this.invoiceIssueTransactions = this.issueInvoicesInfo.transactions.map(({amount, serviceId}) => ({
                    serviceId,
                    prepaymentAmount: amount,
                    percent: 100,
                }))
            } catch (error) {
                console.error(error.response)
                if (this.snackbar) {
                    this.snackbar.message = error.response?.data.errors[0].message
                    this.snackbar.show = true
                }
            }
        }

        async issueInvoices() {
            this.issueInvoiceLoading = true
            try {
                await this.$api.issueInvoice.post({
                    invoiceIssueTransactions: this.invoiceIssueTransactions
                        .filter(({serviceId}) => this.serviceIds.includes(serviceId))
                        .map(({serviceId, prepaymentAmount, percent}) => ({
                            serviceId,
                            ...(+percent !== 100
                                ? {prepaymentAmount: +prepaymentAmount, prepaymentInPercent: false}
                                : this.issueInvoicesInfo.partialInvoicingAllowed && {
                                      prepaymentAmount: 100,
                                      prepaymentInPercent: true,
                                  }),
                        })),
                    invoiceType: this.invoiceType,
                })
                this.modal = false
                this.$emit('issued-invoice', this.invoiceType)
            } catch (error) {
                console.error(error.response)
                if (this.snackbar) {
                    this.snackbar.message = error.response?.data.errors[0].message
                    this.snackbar.show = true
                }
            }
            this.issueInvoiceLoading = false
        }

        cancelIssueInvoices() {
            this.modal = false
            EventBus.$emit(SHOW_INVOICES_MODAL_EVENT, {
                orderId: this.requestInfo.orderId,
                objectId: this.requestInfo.objectId,
                invoiceType: this.invoiceType,
                order: this.requestInfo.order,
            })
        }

        statusColor(status) {
            return runtimeStore.orderServiceStatusColor(runtimeStore.orderServiceStatus(status))
        }

        status(status) {
            return runtimeStore.orderServiceStatus(status)
        }

        get totalPrepaymentAmount() {
            return this.invoiceIssueTransactions
                .filter(({serviceId}) => this.serviceIds.includes(serviceId))
                .reduce((total, {prepaymentAmount}) => total + +prepaymentAmount, 0)
        }
    }
</script>

<style scoped lang="scss">
    .percent-input {
        width: 5em;
    }

    .amount-input {
        width: 8em;
    }
</style>
