<template>
    <v-container class="wrapper mb-8">
        <div class="d-flex align-center justify-center full-width mb-4">
            <v-btn
                x-large
                nuxt
                :to="'/settings'"
                text
                :rounded="false"
                class="v-btn--router footer-link me-4 py-8 px-4"
            >
                <span class="text-h6">
                    {{ $t('my_profile') }}
                </span>
            </v-btn>
            <v-divider vertical class="mx-4 mb-2" inset />
            <v-btn x-large nuxt :to="'/orders'" text :rounded="false" class="v-btn--router footer-link me-4 py-8 px-4">
                <span class="text-h6">
                    {{ $t('orders') }}
                </span>
            </v-btn>
        </div>
        <v-row class="mb-8">
            <v-col>
                <div class="d-flex align-center">
                    <v-avatar size="115" class="me-8">
                        <v-img :src="(user && user.avatar) || '/profile-avatar.png'" />
                    </v-avatar>
                    <div class="d-flex flex-column full-width">
                        <div class="text-h5 font-weight-medium mb-2 black--text full-width d-flex align-center">
                            <span>
                                {{ `${firstName || ''} ${lastName || ''}` }}
                            </span>
                            <div class="flex-grow-1" />
                            <div class="d-flex">
                                <v-dialog v-model="dialog" width="500">
                                    <template v-slot:activator="{on, attrs}">
                                        <div
                                            v-if="isB2C && totalLoyaltyPoints !== undefined && !isMobile"
                                            class="d-flex align-center"
                                        >
                                            <v-icon v-bind="attrs" left color="#8598B4" class="ml-2" v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                            <div class="text-h6 font-weight-regular primary--text">
                                                {{ $t('loyalty_count') }}: {{ totalLoyaltyPoints }}
                                            </div>
                                        </div>
                                    </template>
                                    <v-card>
                                        <v-card-title class="headline grey lighten-2">
                                            {{ $t('loyalty') }}
                                        </v-card-title>

                                        <v-card-text>
                                            <div v-html="$t('about_loyalty')" />
                                            <div class="red--text" v-html="$t('violation_loyalty')" />
                                        </v-card-text>
                                        <v-divider />
                                        <v-card-actions>
                                            <v-spacer />
                                            <v-btn color="primary" @click="dialog = false">
                                                {{ $t('ok') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div>
                        </div>
                        <!--                        <v-btn style="width: max-content" outlined color="warning" class="py-5">-->
                        <!--                            <v-icon left small> mdi-tray-arrow-up </v-icon>-->
                        <!--                            <span class="black&#45;&#45;text font-weight-regular"> {{ $t('upload_photo') }} </span>-->
                        <!--                        </v-btn>-->
                    </div>
                </div>
                <div v-if="isB2C && totalLoyaltyPoints !== undefined && isMobile" class="d-flex align-center mt-8">
                    <v-icon left color="#8598B4" class="ml-2" @click="dialog = true"> mdi-information-outline </v-icon>
                    <div class="text-h6 font-weight-regular primary--text">
                        {{ $t('loyalty_count') }}: {{ totalLoyaltyPoints }}
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row class="form-wrapper mb-8">
            <v-col cols="12">
                <div class="text-h5 black--text font-weight-medium mb-5">{{ $t('user_information') }}</div>
            </v-col>
            <v-col cols="12" class="mb-6 px-6">
                <v-form ref="user-form">
                    <v-row>
                        <v-col cols="12" md="2">
                            <v-select
                                v-model="userData.prefix"
                                dense
                                outlined
                                :append-icon="'mdi-chevron-down'"
                                :items="['Mr', 'Ms', 'Mrs']"
                                :label="`${$t('salutation')}*`"
                                :rules="[requiredRule('salutation')]"
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="firstName"
                                dense
                                outlined
                                :label="$t('first_name')"
                                :rules="validationRules('first_name', [latinRule, requiredRule])"
                                :disabled="lockEditPrivateClientName"
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="lastName"
                                dense
                                outlined
                                :label="$t('last_name')"
                                :rules="validationRules('last_name', [latinRule, requiredRule])"
                                :disabled="lockEditPrivateClientName"
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <phone-field
                                v-model="userData.contactPhone"
                                prepend-inner-icon="mdi-phone"
                                outlined
                                dense
                                :label="$t('phone')"
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="userData.email"
                                dense
                                outlined
                                :label="$t('email')"
                                :rules="validationRules('email', [emailRule, requiredRule])"
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-autocomplete
                                v-model="userData.citizenshipId"
                                :label="$t('citizenship')"
                                dense
                                outlined
                                :append-icon="'mdi-chevron-down'"
                                :items="countries"
                                item-text="name"
                                item-value="id"
                                :rules="[requiredRule('citizenship')]"
                                required
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="2">
                            <v-btn
                                color="primary"
                                elevation="0"
                                block
                                small
                                class="py-4"
                                :loading="loading"
                                @click="save('settings')"
                            >
                                <span class="font-weight-light text-subtitle-2">
                                    {{ $t('save_settings') }}
                                </span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
            <v-col cols="12">
                <div class="text-h5 black--text font-weight-medium mb-5">{{ $t('change_password') }}</div>
            </v-col>
            <v-col cols="12" class="px-6">
                <v-form ref="password-form">
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="password"
                                dense
                                :label="$t('password')"
                                :rules="[requiredRule('password')]"
                                required
                                outlined
                                type="password"
                                autocomplete="new-password"
                                @input="passwordConfirm = null"
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                v-model="passwordConfirm"
                                dense
                                :label="$t('password_confirm')"
                                :rules="[
                                    requiredRule('password_confirm'),
                                    v => v === password || `${$t('password_confirm')} ${$t('validation.match')}`,
                                ]"
                                required
                                outlined
                                validate-on-blur
                                type="password"
                                autocomplete="new-password"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="2">
                            <v-btn
                                color="primary"
                                elevation="0"
                                block
                                class="py-4"
                                small
                                :loading="loadingPassword"
                                @click="save('password')"
                            >
                                <span class="font-weight-light text-subtitle-2">
                                    {{ $t('save_settings') }}
                                </span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import UserSettings from '~/components/account/UserSettings'

    @Component
    export default class UserSettingsMod extends UserSettings {
        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/styles.sass';

    ::v-deep {
        .theme--light.v-btn--active:hover::before,
        .theme--light.v-btn--active::before,
        .theme--dark.v-btn--active:hover::before,
        .theme--dark.v-btn--active::before {
            opacity: 1;
            border-bottom: 7px solid var(--v-yellowlime-base) !important;
            background-color: transparent;
            border-radius: 0;
        }
    }
</style>
