<template>
    <div>
        <v-dialog v-model="modal" hide-overlay width="100%" max-width="1000">
            <v-card v-if="!loaded" color="primary" dark>
                <v-card-text>
                    {{ $t('stand_by') }}
                    <v-progress-linear indeterminate color="white" class="mb-0" />
                </v-card-text>
            </v-card>
            <v-card v-if="loaded" justify-center align-center>
                <div class="mb-4">
                    <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                    <v-card-title class="justify-center">
                        {{ $t('invoices.title') }} {{ requestInfo.orderId }}
                    </v-card-title>
                </div>
                <v-row no-gutters class="mx-4">
                    <v-col cols="12" sm="3">
                        <v-select
                            v-if="isTourOperator"
                            v-model="invoiceType"
                            :items="invoiceTypes"
                            :label="$t('invoice_type')"
                            variant="solo"
                            @update:model-value="getInvoices"
                        />
                    </v-col>
                    <v-col cols="12" sm="3" offset-sm="6" class="d-flex justify-end">
                        <v-btn color="primary" text @click="toggleActive">
                            <v-icon left> mdi-archive-arrow-{{ active ? 'down' : 'up' }} </v-icon>
                            {{ $t(active ? 'archive' : 'standard_mode') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-data-table
                    v-if="invoicesInfo.invoices && invoicesInfo.invoices.length"
                    :headers="headers"
                    :items="invoicesInfo.invoices"
                    :items-per-page="5"
                    class="elevation-1 invoices-table"
                >
                    <template v-slot:item.invoiceName="{item}">
                        <div class="invoice-name-block cursor-pointer">
                            <a @click="printInvoice(item.invoiceId, item.invoiceName)">
                                {{ item.invoiceName }}
                                <v-icon color="blue-darken-1">mdi-file-pdf-box</v-icon>
                            </a>
                        </div>
                    </template>
                    <template v-slot:item.paymentStatus="{item}">
                        <div>
                            {{ $t(`paymentStatus.${item.paymentStatus}`) }}
                        </div>
                    </template>
                    <template v-slot:item.amount="{item}">
                        <div>
                            {{ item.amount | priceFormat(item.amountCurrency) }}
                        </div>
                    </template>
                    <template v-slot:item.amountToPay="{item}">
                        <div>
                            {{ item.amountToPay | priceFormat(item.amountToPayCurrency) }}
                        </div>
                    </template>
                    <template v-slot:item.billDate="{item}">
                        <div>
                            {{ item.billDate | dateFormat }}
                        </div>
                    </template>
                    <template v-slot:item.dueDate="{item}">
                        <div>
                            {{ item.dueDate | dateFormat }}
                        </div>
                    </template>
                    <template v-slot:item.pay="{item}">
                        <v-btn
                            v-if="['BILL', 'PARTIAL_PAID'].includes(item.paymentStatus)"
                            color="primary"
                            size="small"
                            elevation="0"
                            @click="pay(item)"
                        >
                            <v-icon start size="small"> mdi-plus-circle-multiple-outline </v-icon>
                            <span class="text-caption">
                                {{ $t('pay') }}
                            </span>
                        </v-btn>
                    </template>
                    <template v-slot:item.payDate="{item}">
                        <div>
                            {{ item.payDate | dateFormat }}
                        </div>
                    </template>
                </v-data-table>

                <v-card-text v-else>
                    <div class="pt-5">
                        {{ $t('invoices.empty_message') }}
                    </div>
                </v-card-text>
                <v-card-actions v-if="canIssueInvoice" class="justify-end pa-6">
                    <v-btn class="mb-2" color="primary" @click="issueInvoices(invoiceType)">
                        {{ $t('invoices.get_invoice_button') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <invoices-issue-modal @issued-invoice="$emit('issued-invoice', $event)" />
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, ISSUE_INVOICES_MODAL_EVENT, PAY_ORDER_EVENT, SHOW_INVOICES_MODAL_EVENT} from '@/utils/event-bus'
    import {authStore} from '@/utils/store-accessor'
    import {downloadData} from '@/utils/helpers'
    import InvoicesIssueModal from '@/components/modals/InvoicesIssueModal'

    @Component({
        components: {InvoicesIssueModal},
    })
    export default class InvoicesModal extends Vue {
        invoicesInfo = {}
        modal = false
        loaded = false
        requestInfo = {}
        invoiceType = null
        active = true

        created() {
            EventBus.$on(SHOW_INVOICES_MODAL_EVENT, this.showModal)
        }

        beforeUnmount() {
            EventBus.$off(SHOW_INVOICES_MODAL_EVENT, this.showModal)
        }

        async showModal(rq) {
            this.loaded = false
            this.modal = true
            this.requestInfo = rq
            if (this.$config.account.licenseeForCorporateCompany) {
                this.invoiceType = 'SUPPLIER'
            } else this.invoiceType = rq.invoiceType
            await this.getInvoices()
        }

        async getInvoices() {
            this.loaded = false
            try {
                this.invoicesInfo = await this.$api.invoices.get({
                    orderId: this.requestInfo.objectId,
                    invoiceType: this.invoiceType,
                    active: this.active,
                })
            } catch (error) {
                if (this.snackbar) {
                    this.snackbar.message = error.response?.data?.errors?.[0]?.message
                    this.snackbar.show = true
                }
            } finally {
                this.loaded = true
            }
        }

        async pay(item) {
            EventBus.$emit(PAY_ORDER_EVENT, {
                orderId: this.requestInfo.orderId,
                objectId: this.requestInfo.objectId,
                invoiceId: item.invoiceId,
                invoiceType: this.invoiceType,
            })
        }

        toggleActive() {
            this.active = !this.active
            this.getInvoices()
        }

        get headers() {
            const headers = [
                {title: this.$t('invoices.name'), align: 'left', value: 'invoiceName'},
                {title: this.$t('invoices.status'), align: 'left', value: 'paymentStatus'},
                {title: this.$t('invoices.amount'), align: 'left', value: 'amount'},
                {title: this.$t('invoices.amount_to_pay'), align: 'left', value: 'amountToPay'},
                {title: this.$t('invoices.bill_date'), align: 'left', value: 'billDate'},
                {title: this.$t('invoices.due_date'), align: 'left', value: 'dueDate'},
            ]

            if (this.isTO1 || authStore.isTO2 || authStore.isCorporate) {
                headers.push({title: this.$t('pay'), value: 'pay', align: 'center'})
            }

            headers.push({title: this.$t('invoices.pay_date'), align: 'left', value: 'payDate'})
            return headers
        }

        get isTO1() {
            return authStore.isTO1
        }

        get invoiceTypes() {
            if (this.$config.account.licenseeForCorporateCompany) {
                return [{title: this.$t('invoices.supplier'), value: 'SUPPLIER'}]
            }

            return [
                {title: this.$t('invoices.supplier'), value: 'SUPPLIER'},
                {title: this.$t('invoices.client'), value: 'CUSTOMER'},
            ]
        }

        async printInvoice(invoiceId, invoiceName) {
            try {
                const data = await this.$api.printInvoice.get({invoiceId})
                downloadData(data, `${invoiceName}.pdf`)
            } catch (error) {
                if (this.snackbar) {
                    this.snackbar.message = error.response?.data?.errors?.[0]?.message
                    this.snackbar.show = true
                }
            }
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        issueInvoices(invoiceType) {
            EventBus.$emit(ISSUE_INVOICES_MODAL_EVENT, {
                orderId: this.requestInfo.orderId,
                objectId: this.requestInfo.objectId,
                invoiceType: invoiceType,
                order: this.requestInfo.order,
            })
            this.modal = false
        }

        get canIssueInvoice() {
            const failed = ['NO_BILL', 'NO_FULLY_BILL']

            if (this.invoiceType === 'CUSTOMER') {
                return failed.includes(this.requestInfo.order.customerPaymentStatus)
            }

            if (this.invoiceType === 'SUPPLIER') {
                return failed.includes(this.requestInfo.order.supplierPaymentStatus)
            }

            return false
        }
    }
</script>

<style scoped>
    .invoice-name-block {
        max-width: 150px;
    }
</style>
